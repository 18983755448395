// Change layout variables (with "!default" flag)
// Note: please only adjust Bootstrap variables, or make BS-compatible additions
//$enable-rounded: false !default;

$enable-responsive-font-sizes: true;
$hamburger-active-layer-color: white;

// import font
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,600;1,700&display=swap');

$font-family-base: 'Poppins', sans-serif;

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";
@import "~owl.carousel/src/scss/owl.theme.default";

$border-width: 1px;
$border-radius: 2px;
$border-radius-lg: 2px;
$btn-border-radius: 2px;
$btn-border-radius-sm: 2px;
$btn-border-radius-lg: 2px;
$theme-colors: (
        'theme2': $theme2,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$btn-font-weight: bold;
$headings-font-weight: 600;
$enable-shadows: false;
$link-decoration: underline;
$card-border-color: $primary;
$headings-color: $primary;



@import '@/src/scss/main.scss';

// SCSS Components
@import '@/src/scss/components/mijn-sidebar.scss';  // Mijn omgeving css (sidebar theme)
@import '@/src/scss/components/dropdown-sub-menu.scss';

.main-nav {
  padding: 0 2rem 0 2rem;
  @include media-breakpoint-up(xl) {
    border: 1px solid white;

  }
}

.navbar-brand {
  position: relative;
  z-index: 1;
  margin-right: -1px;
}

.top-bar {
  a {
    color: $white;
  }
}

.mobile-hamburger-color {
  @extend .text-white;
  @extend .my-3;
}

.shoppingcart--icon {
  @extend .mr-2;
  @extend .my-auto;
  @extend .text-white;
}

.navbar {
  .navbar-brand--img {
    width: 150px;
    @include media-breakpoint-up(lg) {
      width: 100px;
    }
    @include media-breakpoint-up(xl) {
      width: 200px;
    }
  }
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background: #fff;
}


.shopping-cart-link {
  a {
    color: $white;
    text-decoration: none;
    @include media-breakpoint-down(md) {
      display: block;
      margin-bottom: 1rem;
    }
  }
}

.hero {
  background-size: cover;
  padding-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 93px);
  }
  .c-hero-padding {
    padding-top: 3rem;
    @include media-breakpoint-up(lg) {
      padding-top: 10rem;
    }
  }
}

.content-hero {
  @include media-breakpoint-up(lg) {
    height: 350px;
  }
  .c-hero-padding {
    padding-top: 3rem;
    @include media-breakpoint-up(lg) {
      padding-top: 4rem;
    }
  }
  padding-bottom: 2rem;
  background-size: cover;
}

.gallery-container a {
  width: 540px;
  margin: 5px;
}

.gallery-container a img {
  max-width: 100%;
  height: auto;
}

.hero-video--thumb {
  img {
    border-radius: $border-radius;
  }

  &:hover {
    .play-overlay {
      width: 100px;
      height: 100px;
      top: calc(50% - 50px);
      left: calc(50% - 50px);

    }
  }
}

.play-overlay {
  background: #0000008f;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 35px);
  width: 70px;
  height: 70px;
  left: calc(50% - 35px);
  transition: all 0.3s;

  i {
    position: relative;
    left: 50%;
    transform: translate(-40%, -60%);
    top: 50%;
  }
}

.flavor1 {
  img {
    width: 85%;
  }
}

.flavor-underline::after {
  content: '';
  height: 3px;
  width: 100%;
  bottom: -4px;
  position: absolute;
  background-color:$theme2;
  left: 0;
}

.bg-gradient {
  background: rgb(35,103,199);
  background: linear-gradient(145deg, rgba(35,103,199,1) 0%, $primary 100%);
}
.mt-xl {
  margin-top: 6rem;
}

.round {
  border-radius: 50% !important;
}

.flavor3 {
  width: 300px;
}

.flavor4 {
  right: 15px;
  bottom: 0;
}

.vert-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

footer {
  i {
    color: white;
    font-size: 2.4rem;
  }
  .mobile-social {
    i {
      color: $primary;
    }
  }
  .footer--logo {
    top: -50px;
    height: 100px;
    z-index: 99;
  }
}

.c-mt-nxl {
  margin-top: -9rem;
}

.content-bordered-img {
  border: solid 2px $primary;
  max-width: 100%;
}

.card-link {
  position: absolute;
  bottom: .5rem;
}

.breadcrumb {
  background-color: $primary;
  .breadcrumb-item {
    color: white;
    a {
      color: white;
    }
  }
}

.accordion {
  .card-header {
    background: $primary;
    .btn-link {
      color: $theme-contrast-color;
    }
  }
}

nav {
  .nav-link {
    color: $white !important;
  }
  .nav-item.dropdown {
    .dropdown-menu {
      a {
        color: $primary !important;
      }
    }
  }
}

.nav-collapse .dropdown-menu {
  a {
    color: $primary !important;
  }
}

.bg-glass {
  background-color: rgb(255 255 255 / 8%);
  backdrop-filter: blur(4px);
}

.fr-90 {
  bottom: 15px;
  transform: rotate(270deg);
  left: -36px;
}

.the-line {
  width: 1px;
}

.locked-row {
  bottom: 0;
  left: 16px;
  right: 16px;
  @include media-breakpoint-up(lg) {
    position: absolute;
  }
}

.action-banner-overlay {
  position: absolute;
  left: 3rem;
  top: 2rem;
}

.action-banner-2--content {
  height: 250px;
  background-size: cover;
}

.action-banner-3--content {
  height: 350px;
  background-size: cover;
}

.flavor-overlay {
  position: absolute;
  z-index: 1;
}

.goal {
  position: absolute;
  &.goal--links {
    left: -1px;
    height: 80%;
    top: 10%;
  }
  &.goal--rechts {
    right: -1px;
    height: 80%;
    top: 10%;
  }
  &.goal--links2 {
    left: -1px;
    height: 50%;
    top: 25%;
    @include media-breakpoint-down(xl) {
      display: none !important;
    }
  }
  &.goal--rechts2 {
    right: -1px;
    height: 50%;
    top: 25%;
    @include media-breakpoint-down(xl) {
      display: none !important;
    }
  }
}

.border-xl {
  @include media-breakpoint-up(xl) {
    border: solid 1px $white;
  }
}

.border-left-xl {
  border-left: solid 15px $primary !important;
}

.square-overlay {
  position: absolute;
  color: $white;
  bottom: 0;
}

.inset-c {
  top: -43px;
  left: 15px;
  right: 15px;
  bottom: 0;
}

.c-mh-215 {
  min-height: 215px;
}

.has-user-content {
  ul {
    padding-left: 20px;
    li {
      list-style-image: url('../svg/shield.svg');
      margin-bottom: .5rem;
    }
  }

}

.action-banner-3-overlay {
  right: 1rem;
  bottom: 1rem;
  width: 350px;
  @include media-breakpoint-down(sm) {
    width: 75%;
  }
}

.click-label {
  transform: rotate(-90deg);
  position: absolute;
  width: 140px;
  left: -88px;
  bottom: 51px;
}

.c-h-140 {
  height: 140px;
}

.click-hover:hover {
  .click-label {
    background: #288e55;
  }
}

.blur {
  filter: blur(5px);
}

.object-fit-cover {
  object-fit: cover !important;
}

.contact-shield {
  width: 50%;
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
}

.c-loc {
  bottom: 45px;
  left: -10px;
  position: absolute;
}

.c-w-45 {
  width: 45px !important;
}

.hero-video--thumb {
  cursor: pointer !important;
}

// Custom parts overview
.part--card {
  padding: 1rem;
  .card-body {
    padding: 1rem 0 0 0;
    .card-title {
      a{
        text-decoration: none;
        color: $dark;
      }
    }
  }
}

.btn-group .dropdown-menu {
  top:100%;
  margin-top: 10px !important;
}

@include media-breakpoint-down(md) {
  .btn-group .dropdown-menu {
    top: auto !important;;
    margin-top: 61px !important;
  }
}
