$primary: #114c66 !default;
$theme2: #30ac67;
$succes: $theme2;
$theme-contrast-color: #fff !default;

@import '@/src/scss/preset/voetbalopleidingscentrum.scss';
@import "~daterangepicker";

$lg-path-fonts: '~lightgallery/fonts';
$lg-path-images: '~lightgallery/images';
@import "~lightgallery/scss/lightgallery";
@import "~lightgallery/scss/lg-video";
